// const ENVIRONMENT = 'DEV';

const Constant = {
    APP_NAME: "arbeit",
    'statusSuccess': 'Success',
    'statusFailure': 'Failure',
    'perPage': 10,
    "successCode": 200,
    "updateSuccessCode": 202,
    "apiBasePath" : process.env.REACT_APP_BASE_URL
}

// const basePath = {
//    PROD: {
//       apiBasePath: "https://api.arbeit.com"
//    },
//    DEV:{
//       apiBasePath: "http://13.234.187.136:5130/api"
//    },
//    LOCAL:{
//       apiBasePath: "http://localhost:5130/api"
//    },
// }

// if (ENVIRONMENT === 'production')
//       Constant.apiBasePath = basePath["PROD"].apiBasePath;
// else if(ENVIRONMENT === 'DEV')
//       Constant.apiBasePath = basePath["DEV"].apiBasePath;
// else if(ENVIRONMENT === 'LOCAL')
//       Constant.apiBasePath = basePath["LOCAL"].apiBasePath;

module.exports = Constant;