import { axios } from "../lib/axios"

const getSubscriptionList= async(filter)=>{
    return axios.get('/admin/subscription-plan', { params: filter});
}
const getSubscriptionById= async(id)=>{
    return axios.get(`/admin/subscription-plan/${id}`);
}
const postSubscription= async (payload)=>{
    return axios.post('/admin/subscription-plan', payload);
}
const updateSubscription= async (id,payload)=>{
    return axios.put(`/admin/subscription-plan/${id}`, payload);
}

export const Membership={
    getSubscriptionList,
    postSubscription,
    updateSubscription,
    getSubscriptionById
}