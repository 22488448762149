import { useEffect, useState } from "react";

import patchApiCall from "../../Services/patchApiCall";

import { toast, ToastContainer } from "react-toastify";
import { Pagination } from "rsuite";
import Parse from "html-react-parser";

import "rsuite/Pagination/styles/index.css";
import MemberShipModel from "../../Components/MemberPlan/MemberShipModel";
import { Membership } from "../../Services/membership";
import getApiCall from "../../Services/getApiCall";

const DataList = () => {
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [status, setStatus] = useState(true);
  const [details, setDetails] = useState({});
  const [request, setRequestParms] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchData(status);
  }, [status, page]);

  const fetchData = async (currentStatus) => {
    try {
      const data = await Membership.getSubscriptionList({
        pageNo: page,
        status: currentStatus,
      });
      // const data = await postApiCall("/admin/plan/get-list", { page, status: currentStatus }, true);
      if (data.status) {
        setPage(data.page);
        setTotalPage(data.total);
        setTotalLength(data.total * 10);
        setRecords(data.data);
      } else {
        toast.error(data.message || "Network error!!");
        setRecords([]);
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    }
  };

  const handleStatusChange = async (id) => {
    try {
      const data = await patchApiCall(
        `/admin/subscription-plan/change-status/${id}`,
        {},
        true
      );
      if (data.status) {
        toast.success(data.message);
        fetchData(status);
      } else {
        toast.error(data.message || "Network error!!");
      }
    } catch (error) {
      toast.error("An error occurred while changing status");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this plan?")) {
      try {
        const data = await patchApiCall(
          `/admin/subscription-plan/delete/${id}`,
          {},
          true
        );
        if (data.status) {
          toast.warning(data.message);
          fetchData(status);
        } else {
          toast.error(data.message || "Network error!!");
        }
      } catch (error) {
        toast.error("An error occurred while deleting data");
      }
    }
  };

  const handleCreateOrUpdate = async (payload) => {
    try {
      let data;
      if (details.name) {
        data = await Membership.updateSubscription(details._id, payload);
      } else {
        data = await Membership.postSubscription(payload);
      }
      if (data.status) {
        setIsOpen(false);
        toast.success(data.message);
        window.location.reload(false);
      } else {
        toast.error(data.message || "Network error!!");
      }
    } catch (error) {
      toast.error("An error occurred while saving data");
    }
  };
  async function isActive(id) {
    let data = await patchApiCall(
      `/admin/subscription-plan/${id}/status`,
      {},
      true
    );
    if (data.status) {
      toast.success(data.message);
      fetchData(page, status);
    } else {
      toast.error(
        data.message !== undefined ? data.message : "Network error!!"
      );
    }
  }
  async function getDetails(id) {
    let data = await getApiCall(
      "/admin/subscription-plan/" + id,
      {},
      true
    );
    if (data.status) {
      toast.success(data.message);
      setDetails(data.data);
    } else {
      toast.error(
        data.message !== undefined ? data.message : "Network error!!"
      );
    }
  }

  async function isDeleted(id) {
    if (window.confirm("Are you sure you want to delete this plan?")) {
      let data = await patchApiCall(
        `/admin/subscription-plan/${id}/delete`,
        {},
        true
      );
      if (data.status) {
        toast.warning(data.message);
        fetchData(page, status);
      } else {
        toast.error(
          data.message !== undefined ? data.message : "Network error!!"
        );
      }
    }
  }
  const handleGetDetails = async (id) => {
    try {
      // const data = await getApiCall(`/admin/plan/details/${id}`, {}, true);
      const data = await Membership.getSubscriptionById(id);
      if (data.status) {
        setDetails(data.data);
      } else {
        toast.error(data.message || "Network error!!");
      }
    } catch (error) {
      toast.error("An error occurred while fetching details");
    }
  };

  const renderRecords = () =>
    records.length > 0 ? (
      records.map((el, i) => (
        <tr key={el._id}>
          <th scope="row">{i + 1}</th>
          <td>{el.name}</td>
          <td>{el.type}</td>
          <td>{el.price}</td>
          <td><div style={{
            width:'100%',
            maxWidth:'250px'
          }}>{Parse(el.description)}</div></td>
          <td>{el.userType}</td>
          <td>{el.noOfJobs}</td>
          <td className="text-end">
            {el.isActive ? (
              <a
                href="#"
                className="btn btn-sm btn-green btn-icon m-1"
                onClick={() => {
                  isActive(el._id);
                }}
              >
                <img src="images/check-white.svg" />
              </a>
            ) : (
              <a
                href="#"
                className="btn btn-sm btn-light btn-icon m-1"
                onClick={() => {
                  isActive(el._id);
                }}
              >
                <img src="images/check.svg" />
              </a>
            )}
            <a
              className="btn btn-light btn-sm btn-icon m-1"
            >
              <img
                src="images/edit.png"
                onClick={() => {
                  setIsOpen(true);
                  getDetails(el._id);
                }}
                height={16}
                width={16}
              />
            </a>
            <button
              type="button"
              className="btn btn-light btn-sm btn-icon  m-1"
            >
              <img
                src="images/trash.svg"
                onClick={() => {
                  isDeleted(el._id);
                }}
              />
            </button>
          </td>
          {/* <td className="text-end">
              {el.status ? (
                <a
                  href="#"
                  className="btn btn-sm btn-green btn-icon m-1"
                  onClick={() => {
                    handleStatusChange(el._id);
                  }}
                >
                  <img src="images/check-white.svg" />
                </a>
              ) : (
                <a
                  href="#"
                  className="btn btn-sm btn-light btn-icon m-1"
                  onClick={() => {
                    handleStatusChange(el._id);
                  }}
                >
                  <img src="images/check.svg" />
                </a>
              )}
              <a
                href="#planModal"
                data-bs-toggle="modal"
                className="btn btn-light btn-sm btn-icon m-1"
              >
                <img
                  src="images/edit.png"
                  width={15}
                  height={15}
                  onClick={() => {
                    handleGetDetails(el._id);
                  }}
                />
              </a>
              <button
                type="button"
                className="btn btn-light btn-sm btn-icon  m-1"
              >
                <img
                  src="images/trash.svg"
                  onClick={() => {
                    handleDelete(el._id);
                  }}
                />
              </button>
            </td> */}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="8" className="text-center">
          No results found
        </td>
      </tr>
    );

  return (
    <>
      <ToastContainer />
      <div className="page-header">
        <h4>Membership Plan Management</h4>
      </div>

      <div className="form-box form-filter">
        <div className="row">
          <div className="col-12 col-lg-3">
            <ul className="nav nav-pills nav-justified tabs-nav bg-white">
              <li className="nav-item">
                <a
                  className={`nav-link ${status && "active"}`}
                  href="#ActiveTab"
                  onClick={() => setStatus(true)}
                >
                  Active
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${!status && "active"}`}
                  href="#InactiveTab"
                  onClick={() => setStatus(false)}
                >
                  Inactive
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-9 text-end">
            <button
              className="btn btn-success"
              onClick={() => {setDetails({}); setIsOpen(true);}}
            >
              Add New Plan
            </button>
          </div>
        </div>
      </div>

      <div className="table-card">
        <table className="table table-white">
          <thead>
            <tr className="table-tr-light">
              <th>Sr. No</th>
              <th>Package Name</th>
              <th>Package Type</th>
              <th>Price</th>
              <th>Description</th>
              <th>User Type</th>
              <th>No of Post Jobs</th>
              <th className="text-end">Action</th>
            </tr>
          </thead>
          <tbody>{renderRecords()}</tbody>
        </table>
      </div>

      <Pagination
        total={totalLength}
        limit={10}
        activePage={page}
        onChangePage={(page) => setPage(page)}
        className="pagination justify-content-center"
      />

    {isOpen  &&  <MemberShipModel
        details={details}
        createNewContent={handleCreateOrUpdate}
        setRequest={setRequestParms}
        request={request}
        setIsOpen={setIsOpen}
      />}
    </>
  );
};

export default DataList;
