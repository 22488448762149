const warningMessages={
    PackageName:"Package name is required",
    PackageNameLength:"Package name should be less then 20 characters",
    PackageType:"Package type is required",
    UserType:"User type is required",
    Price:"Price is required",
    JobCredits:"Job Credits is required",
    DatabaseCredits:"Database credits is required",
    PlanDescription:"Plan Description is required",
    NoOfJobs:"Number of post job is required",
    Frequency:"Frequency is required"
}
export const ConstantValues={
    warningMessages
};