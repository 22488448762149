import React, { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";
import { useForm } from 'react-hook-form';
import { Button, Form, Modal } from 'react-bootstrap';
import { ConstantValues } from "../ConstantValues";
import '../../../src/'
import { Icon } from "@iconify/react";

const MemberShipModel = ({
  details = {},
  createNewContent,
  setRequest,
  request,
  setIsOpen,
}) => {
  const { register, reset,trigger, handleSubmit,setValue, watch, formState: { errors } } = useForm();
  const renderPackageOptions = () => {
    const packageTypes = [
      {
        value: "",
        label: "Select Package Type",
      },
      {
        value: "database",
        label: "Database",
      },
      {
        value: "jobPosting",
        label: "Job Posting",
      },
      {
        value: "bundle",
        label: "Bundle",
      },
    ];
   
    return packageTypes.map((item) => (
      <option
        key={item.value}
        value={item.value}
        selected={details?.type === item.value}
      >
        {item.label}
      </option>
    ));
  };
  const renderPackageOptionsJobSeeker = () => {
   
      const packageTypes = [
        {
          value: "",
          label: "Select Package Type",
        },
        {
          value: "free",
          label: "Free",
        },
        {
          value: "basic",
          label: "Basic",
        },
        {
          value: "premium",
          label: "Premium",
        },
      ];
    return packageTypes.map((item) => (
      <option
        key={item.value}
        value={item.value}
        selected={details?.type === item.value}
      >
        {item.label}
      </option>
    ));
  };

  useEffect(()=>{
    setValue('name', details.name);
    setValue('price', details.price);
    setValue('type', details.type);
    setValue('notification', details.notification?.toString());
    setValue('jobCredits', details.jobCredits);
    setValue('dbCredits', details.dbCredits);
    debugger
    setValue('activeFilter', details.activeFilter?.toString());
    setValue('jobVisibility', details.jobVisibility?.toString());
    setValue('jobBranding', details.jobBranding?.toString());
    setValue('isJobHightLighted', details.isJobHightLighted?.toString());
    setValue('description', details.description);
    setValue('frequency', details.frequency);
    setValue('userType', details.userType);
  },[details])

  useEffect(() => {
    register("description", { 
      required: "Description is required", 
      validate: (value) => value?.trim() !== "" || "Description cannot be empty" 
    });
  }, [register]);

  const renderFrequencyOptions = () => {
    const packageTypes = [
      {
        value: "",
        label: "Select Frequency",
      },
      {
        value: "monthly",
        label: "Monthly",
      },
      {
        value: "quarterly",
        label: "Quarterly",
      },
      {
        value:"yearly",
        label:"Yearly"
      }
    ];
    return packageTypes.map((item) => (
      <option
        key={item.value}
        value={item.value}
        selected={details?.type === item.value}
      >
        {item.label}
      </option>
    ));
  };

  const renderUserTypeOptions = () => (
    <>
    <option value="">
    Select User Type
      </option>
      <option value="jobSeeker" >
        Job Seeker
      </option>
      <option value="employer">
        Employer
      </option>
    </>
  );

  const onSubmit = data => {
    if(data.type === 'free'){
      data.price=0;
      data.frequency= 'free'
    }
    data.isJobHightLighted = (data.isJobHightLighted === "true");
    data.jobBranding = (data.jobBranding === "true");
    data.jobVisibility = (data.jobVisibility === "true");
    data.notification = (data.notification === "true");
    createNewContent(data);
  };
  return (
    <Modal show={true} backdrop="static" keyboard={false}>
    <Modal.Header style={{
      width:'100%'
    }}>
      <Modal.Title style={{
      width:'100%'
    }}>
      <div className="d-flex justify-content-between w-100"><div>{details?.name ? "Edit" : "Add"} Membership Plan</div> <Icon icon="basil:cross-outline" width={30} onClick={()=>{setIsOpen(false); reset();}} /></div></Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form onSubmit={handleSubmit(onSubmit)}>
                <div className="login-white-box shadow-none pt-1">
                  <div className="form-group">
                    <span>Package Name*</span>
                    <input
                      type="text"
                      className="form-control form-ctrl"
                      placeholder="Enter"
                      {...register("name", {required: ConstantValues.warningMessages.PackageName, maxLength:{
                        value: 20,
                        message: ConstantValues.warningMessages.PackageNameLength
                      }})}
                    />
                     {errors?.name?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.name?.message}
                        </Form.Text>
                  )}
                  </div>
                  <div className="form-group">
                    <span>User Type*</span>
                    <select
                      className="form-control form-ctrl"
                      {...register("userType", { required: ConstantValues.warningMessages.UserType })}
                    >
                      {renderUserTypeOptions()}
                    </select>
                    {errors?.userType?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.userType?.message}
                        </Form.Text>
                  )}
                  </div>
                  <div className="form-group">
                    <span>Package Type*</span>
                    <select
                      className="form-control form-ctrl"
                      {...register("type", { required: ConstantValues.warningMessages.PackageType })}
                    >
                      {watch('userType')=== "employer" ?  renderPackageOptions(): renderPackageOptionsJobSeeker() }
                    </select>
                    {errors?.type?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.type?.message}
                        </Form.Text>
                  )}
                  </div>
                  {watch('type') !== "free" && <div className="form-group">
                    <span>Frequency*</span>
                    <select
                      className="form-control form-ctrl"
                      {...register("frequency", { required: ConstantValues.warningMessages.Frequency })}
                    >
                      {renderFrequencyOptions()}
                    </select>
                    {errors?.frequency?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.frequency?.message}
                        </Form.Text>
                  )}
                  </div>}
                  
                  {watch('userType') === "Employer" && (
                    <div className="form-group">
                      <span>Number of Post Jobs*</span>
                      <input
                        type="number"
                        className="form-control form-ctrl"
                        placeholder="Enter"
                        {...register("noOfJobs", { required: watch('userType') === "Employer" ? ConstantValues.warningMessages.NoOfJobs: false })}
                      />
                      {errors?.noOfJobs?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.noOfJobs?.message}
                        </Form.Text>
                  )}
                    </div>
                  )}

                  {watch('type')!== "free" &&  <div className="form-group">
                    <span>Price*</span>
                    <input
                      type="number"
                      className="form-control form-ctrl"
                      placeholder="Enter"
                      {...register("price", { required: watch('type') !== "free"?ConstantValues.warningMessages.Price: false })}
                    />
                     {errors?.price?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.price?.message}
                        </Form.Text>
                  )}
                  </div>}
                  {watch('userType')=== "employer" && ['bundle','jobPosting','database'].includes(watch('type')) &&   <div className="form-group">
                    <label style={{
                      fontSize:'16px',
                      marginBottom:'18px'
                    }}>Configure plan</label>
                    <div className="d-flex gap-3">
                      {['bundle','jobPosting'].includes(watch('type')) && <div className="w-100">
                        <div>
                          <span>Job Credits*</span>
                        </div>
                        <div>
                          {" "}
                          <input
                            type="number"
                            className="form-control form-ctrl"
                            placeholder="Enter"
                            {...register("jobCredits", { required: watch('userType')=== "employer"? ConstantValues.warningMessages.JobCredits: false })}
                          />
                          {errors?.jobCredits?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.jobCredits?.message}
                        </Form.Text>
                  )}
                        </div>
                      </div>}
                      {['bundle','database'].includes(watch('type')) && <div className="w-100">
                        <div>
                          <span>Database Credits*</span>
                        </div>
                        <div>
                          {" "}
                          <input
                            type="text"
                            className="form-control form-ctrl"
                            placeholder="Enter"
                            {...register("dbCredits", { required: watch('userType')=== "employer"?ConstantValues.warningMessages.DatabaseCredits: false })}
                        
                          />
                             {errors?.dbCredits?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.dbCredits?.message}
                        </Form.Text>
                  )}
                        </div>
                      </div>}
                    </div>
                  </div>}
                  {watch('userType')=== "employer" && ['database','jobPosting','bundle'].includes(watch('type'))  && (
                    <div className="form-group">
                      <span>Area wise filter*</span>
                      <div className="d-flex gap-5 mt-2 mb-0">
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="activeFilter"
                            value={true}
                            checked={watch('activeFilter') === 'true' || watch('activeFilter') === true}
                            {...register('activeFilter', {
                              required: (['jobPosting', 'database'].includes(watch('type')) && watch('userType')=== "employer")  ? 'Please select an option' : false,
                              setValueAs: (value) => value === true || value === 'true', // Converts string to boolean
                            })}
                          />
                          <span>Yes</span>
                        </div>
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="activeFilter"
                            value={false}
                            checked={watch('activeFilter') === 'false' || watch('activeFilter') === false}
                            {...register('activeFilter', {
                              required: (['jobPosting', 'database'].includes(watch('type')) && watch('userType')=== "employer") ? 'Please select an option' : false,
                              setValueAs: (value) => value === true || value === 'true', // Converts string to boolean
                            })}
                          />
                          No
                        </div>
                      </div>
                    </div>
                  )}

                  {watch('userType')=== "employer"  && ['bundle','jobPosting'].includes(watch('type'))  && 
                  <div className="gap-3 d-flex flex-column">
                    <div lassName="form-group ">
                      <span>Highlighting Job *</span>
                      <div className="d-flex gap-5 mt-2">
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="isJobHightLighted"
                            value={true}
                            checked={watch('isJobHightLighted')=== 'true' || watch('isJobHightLighted')=== true}
                            {...register("isJobHightLighted", { required: (['bundle'].includes(watch('type')) && watch('userType')=== "employer") ? "Please select an option": false, setValueAs: (value) => value === 'true',  })}
                          />
                          <span>Yes</span>
                        </div>
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="isJobHightLighted"
                            value={false}
                            checked={watch('isJobHightLighted')=== 'false' || watch('isJobHightLighted')=== false}
                            {...register("isJobHightLighted", { required: (['bundle'].includes(watch('type')) && watch('userType')=== "employer") ? "Please select an option": false, setValueAs: (value) => value === 'true',  })}
                          />
                          No
                        </div>
                      </div>
                      {errors?.isJobHightLighted?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.isJobHightLighted?.message}
                        </Form.Text>
                  )}
                    </div>
                    <div lassName="form-group ">
                      <span>Notification Job *</span>
                      <div className="d-flex gap-5 mt-2">
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="notification"
                            value={true}
                            checked={watch('notification')=== 'true' || watch('notification')=== true}
                            {...register("notification", { required: (['bundle'].includes(watch('type')) && watch('userType')=== "employer") ? "Please select an option": false,setValueAs: (value) => value === 'true',  })}
                          />
                          <span>Yes</span>
                        </div>
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="notification"
                            value={false}
                            checked={watch('notification')=== 'false' || watch('notification')=== false}
                            {...register("notification", { required:(['bundle'].includes(watch('type')) && watch('userType')=== "employer") ? "Please select an option":false, setValueAs: (value) => value === 'true',  })}
                          />
                          No
                        </div>
                      </div>
                      {errors?.notification?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.notification?.message}
                        </Form.Text>
                  )}
                    </div>
                    <div lassName="form-group">
                      <span>Job Branding*</span>
                      <div className="d-flex gap-5 mt-2">
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="jobBranding"
                            value={true}
                            checked={watch('jobBranding')=== 'true' || watch('jobBranding')=== true}
                            {...register("jobBranding", { required: (['bundle'].includes(watch('type')) && watch('userType')=== "employer")?"Please select an option":false, setValueAs: (value) => value === 'true',  })}
                          />
                          <span>Yes</span>
                        </div>
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="jobBranding"
                            value={false}
                            checked={watch('jobBranding')=== 'false' || watch('jobBranding')=== false}
                            {...register("jobBranding", { required: (['bundle'].includes(watch('type')) && watch('userType')=== "employer")?"Please select an option" : false, setValueAs: (value) => value === 'true',  })}
                          />
                          No
                        </div>
                      </div>
                      {errors?.jobBranding?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.jobBranding?.message}
                        </Form.Text>
                  )}
                    </div>
                    <div lassName="form-group">
                      <span>Increase job visibility*</span>
                      <div className="d-flex gap-5 mt-2">
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="jobVisibility"
                            value={true}
                            checked={watch('jobVisibility')=== 'true' || watch('jobVisibility')=== true}
                            {...register("jobVisibility", { required: (['bundle'].includes(watch('type')) && watch('userType')=== "employer")? "Please select an option":false, setValueAs: (value) => value === 'true',  })}
                          />
                          <span>Yes</span>
                        </div>
                        <div className="d-flex gap-2">
                          <input
                            type="radio"
                            name="jobVisibility"
                            value={false}
                            checked={watch('jobVisibility')=== 'false' || watch('jobVisibility')=== false}
                            {...register("jobVisibility", { required: (['bundle'].includes(watch('type')) && watch('userType')=== "employer")?"Please select an option":false, setValueAs: (value) => value === 'true',  })}
                          />
                          No
                        </div>
                      </div>
                      {errors?.jobVisibility?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.jobVisibility?.message}
                        </Form.Text>
                  )}
                    </div>
                    </div>
                  }

                  <div className="form-group editor-section" style={{
                    marginTop:'8px'
                  }}>
                    <div className="" style={{
                      paddingBottom:'8px'
                    }}>Description*</div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={details?.description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue("description", data);  
                        trigger("description");         // Trigger validation if needed
                      }}
                    />
                     {errors?.description?.message && (
                      <Form.Text style={{ color: 'red' }}>
                        {errors?.description?.message}
                        </Form.Text>
                  )}
                  </div>
                  <div className="btn-width m-auto">
                    <button
                      type="submit"
                      className="btn btn-block btn-normal btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>

      </Modal.Body>

  </Modal>
  );
};

export default MemberShipModel;
