import Axios from 'axios';
import loginUser from '../Services/loginUser';
import Constant from '../Components/Constant';

function authRequestInterceptor(config){
    let {accessToken} = loginUser();
    config.headers = config.headers ?? {};
    if (accessToken) {
        config.headers.authorization = `${accessToken}`;
      }
      config.headers.Accept = 'application/json';
      return config;
}
export const axios = Axios.create({
    baseURL: Constant.apiBasePath,
  });
axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (err)=>{
        if(err.response.status === 401){
            sessionStorage.clear();
            window.location.href = '/';
            return false;
        }
        if(err.response.data.message !== undefined){
            // alert(err.response.data.message);
            return err.response.data;
        }else{
            alert(err.response.data.message);
            return false;
        }
    })