import Axios from "axios";
import Constant from "../Components/Constant";
import loginUser from "./loginUser";
import {toast} from "react-toastify";

export default function putApiCall(Url, bodyData, isReturn=false) {
    let { accessToken } = loginUser();
    return Axios.put(Constant.apiBasePath + Url,bodyData,{ headers: { authorization: accessToken }})
             .then(data => {
            console.log('put api hook success',  data.data);
            if(isReturn === false){
            if(!data.data.status){
                toast.error(data.data.message);
            }
            return data.data;
        }else{
            if(!data.data.status){
                toast.error(data.data.message);
            }
            return data.data;
        }

        })
        .catch(err => {
            if(err.response.status === 401){
                sessionStorage.clear();
                window.location.href = '/';
                return false;
            }
            if(err.response.data.message !== undefined){
                alert(err.response.data.message);
                return false;
            }else{
                alert(err.response.data.message);
                return false;
            }
        })

}


